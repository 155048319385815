var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitUpdate.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "b-tabs",
        {
          staticClass: "mb-0 tabs-school",
          attrs: { "content-class": "mt-0", vertical: "" },
        },
        [
          _c(
            "b-tab",
            {
              attrs: { active: "" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Informações"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-card",
                      { staticClass: "mb-1" },
                      [
                        _c("b-card-text", [
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col-md-12" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Razão Social "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("b-form-input", {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.infos.trading_name.$error,
                                    },
                                    model: {
                                      value: _vm.infos.trading_name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infos, "trading_name", $$v)
                                      },
                                      expression: "infos.trading_name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Nome Fantasia "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group mb-m-1 mb-0" },
                                [
                                  _c("b-form-input", {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.infos.company_name.$error,
                                    },
                                    model: {
                                      value: _vm.infos.company_name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infos, "company_name", $$v)
                                      },
                                      expression: "infos.company_name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" CNPJ "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group mb-m-1 mb-0" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["##.###.###/####-##"],
                                        expression: "['##.###.###/####-##']",
                                      },
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.infos.document,
                                        expression: "infos.document",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      "is-invalid":
                                        _vm.$v.infos.document.$error,
                                    },
                                    attrs: {
                                      type: "tel",
                                      placeholder: "000.000.000-00",
                                    },
                                    domProps: { value: _vm.infos.document },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.infos,
                                          "document",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Cor principal "),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.infos.background,
                                    expression: "infos.background",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.$v.infos.background.$error,
                                },
                                style: "color: " + _vm.infos.background + ";",
                                domProps: { value: _vm.infos.background },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.infos,
                                      "background",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-card",
                            { staticClass: "mb-1" },
                            [
                              _c("b-card-text", [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" WhatsApp "),
                                  _c("i", { staticClass: "bi bi-whatsapp" }),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: [
                                        "(##) #####-####",
                                        "(##) ####-####",
                                      ],
                                      expression:
                                        "['(##) #####-####', '(##) ####-####']",
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.contacts.whatsapp,
                                      expression: "contacts.whatsapp",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.contacts.whatsapp.$error,
                                  },
                                  attrs: {
                                    type: "tel",
                                    placeholder: "(00) 0000-0000",
                                  },
                                  domProps: { value: _vm.contacts.whatsapp },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.contacts,
                                        "whatsapp",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-8" },
                        [
                          _c(
                            "b-card",
                            { staticClass: "mb-1" },
                            [
                              _c("b-card-text", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex",
                                    staticStyle: {
                                      "align-items": "center",
                                      "column-gap": "20px",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("label", { attrs: { for: "" } }, [
                                        _c("i", {
                                          staticClass:
                                            "text-danger bi bi-record-circle",
                                        }),
                                        _vm._v(" WhatsApp Botão "),
                                        _c("i", {
                                          staticClass: "bi bi-whatsapp",
                                        }),
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: [
                                              "(##) #####-####",
                                              "(##) ####-####",
                                            ],
                                            expression:
                                              "['(##) #####-####', '(##) ####-####']",
                                          },
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.contacts.whatsapp_button,
                                            expression:
                                              "contacts.whatsapp_button",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: {
                                          "is-invalid":
                                            _vm.$v.contacts.whatsapp_button
                                              .$error,
                                        },
                                        attrs: {
                                          type: "tel",
                                          placeholder: "(00) 0000-0000",
                                        },
                                        domProps: {
                                          value: _vm.contacts.whatsapp_button,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.contacts,
                                              "whatsapp_button",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass:
                                              "custom-control-areas custom-control-success float-left",
                                            attrs: {
                                              name: "check-button",
                                              switch: "",
                                              inline: "",
                                              checked:
                                                _vm.infos.display_whatsapp_home,
                                              value: 1,
                                            },
                                            model: {
                                              value:
                                                _vm.infos.display_whatsapp_home,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.infos,
                                                  "display_whatsapp_home",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "infos.display_whatsapp_home",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "switch-icon-left",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "bi bi-check",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "switch-icon-right",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "bi bi-x",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" Exibir na Home "),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "b-card",
                      { staticClass: "mb-1" },
                      [
                        _c("b-card-text", [
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Sales Force - OID "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group mb-1 mb-md-0" },
                                [
                                  _c("b-form-input", {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.infos.sales_force_oid.$error,
                                    },
                                    model: {
                                      value: _vm.infos.sales_force_oid,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.infos,
                                          "sales_force_oid",
                                          $$v
                                        )
                                      },
                                      expression: "infos.sales_force_oid",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Sales Force - Record Type "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group mb-1 mb-md-0" },
                                [
                                  _c("b-form-input", {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.infos.sales_force_record_type
                                          .$error,
                                    },
                                    model: {
                                      value: _vm.infos.sales_force_record_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.infos,
                                          "sales_force_record_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "infos.sales_force_record_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Código GTM "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group mb-0 mb-md-0" },
                                [
                                  _c("b-form-input", {
                                    class: {
                                      "is-invalid": _vm.$v.infos.gtm.$error,
                                    },
                                    model: {
                                      value: _vm.infos.gtm,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infos, "gtm", $$v)
                                      },
                                      expression: "infos.gtm",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-card",
                      { staticClass: "mb-1" },
                      [
                        _c("b-card-text", [
                          _c(
                            "div",
                            {
                              staticClass: "form-row",
                              staticStyle: {
                                display: "flex",
                                "align-content": "center",
                                "justify-content": "center",
                                "align-items": "center",
                                "flex-direction": "row",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass:
                                        "custom-control-areas custom-control-success float-left",
                                      attrs: {
                                        name: "check-button",
                                        switch: "",
                                        inline: "",
                                        checked: _vm.infos.black_friday_active,
                                        value: 1,
                                      },
                                      model: {
                                        value: _vm.infos.black_friday_active,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.infos,
                                            "black_friday_active",
                                            $$v
                                          )
                                        },
                                        expression: "infos.black_friday_active",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "switch-icon-left" },
                                        [
                                          _c("i", {
                                            staticClass: "bi bi-check",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "switch-icon-right" },
                                        [_c("i", { staticClass: "bi bi-x" })]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" Ativar Black Friday "),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-md-8" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Consys - Canal do aluno "),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "form-group mb-0 mb-md-0" },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "is-invalid":
                                          _vm.$v.infos.channel.$error,
                                      },
                                      model: {
                                        value: _vm.infos.channel,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.infos, "channel", $$v)
                                        },
                                        expression: "infos.channel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-md-12" },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass:
                                        "custom-control-areas custom-control-success float-left",
                                      attrs: {
                                        name: "check-button",
                                        switch: "",
                                        inline: "",
                                        checked: _vm.tickets,
                                        value: 1,
                                        "unchecked-value": 0,
                                      },
                                      model: {
                                        value: _vm.tickets,
                                        callback: function ($$v) {
                                          _vm.tickets = $$v
                                        },
                                        expression: "tickets",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "switch-icon-left" },
                                        [
                                          _c("i", {
                                            staticClass: "bi bi-check",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "switch-icon-right" },
                                        [_c("i", { staticClass: "bi bi-x" })]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" Abertura de Tickets "),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Facebook API"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-card",
                      { staticClass: "mb-1" },
                      [
                        _c("b-card-text", [
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Facebook - Pixel ID "),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.infos.facebook_pixel_id,
                                      expression: "infos.facebook_pixel_id",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.infos.facebook_pixel_id.$error,
                                  },
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.infos.facebook_pixel_id,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.infos,
                                        "facebook_pixel_id",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Facebook App - ID "),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.infos.facebook_app_id,
                                      expression: "infos.facebook_app_id",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.infos.facebook_app_id.$error,
                                  },
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.infos.facebook_app_id,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.infos,
                                        "facebook_app_id",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Facebook App - Chave secreta "),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.infos.facebook_app_secret_key,
                                      expression:
                                        "infos.facebook_app_secret_key",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.infos.facebook_app_secret_key
                                        .$error,
                                  },
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.infos.facebook_app_secret_key,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.infos,
                                        "facebook_app_secret_key",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-12" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Token do usuário do sistema "),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.v-dark",
                                      modifiers: {
                                        hover: true,
                                        "v-dark": true,
                                      },
                                    },
                                  ],
                                  staticClass:
                                    "bi bi-question-circle text-1rem",
                                  attrs: {
                                    title:
                                      "Acesse o Facebook Business > Confogurações > Usuários > Usuários do sistema. ",
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("b-form-input", {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.infos.facebook_user_token.$error,
                                    },
                                    model: {
                                      value: _vm.infos.facebook_user_token,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.infos,
                                          "facebook_user_token",
                                          $$v
                                        )
                                      },
                                      expression: "infos.facebook_user_token",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Logos"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c("b-card", { staticClass: "mb-1" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Favicon "),
                      ]),
                      _c("input", {
                        staticClass: "form-control-file hidden",
                        attrs: {
                          id: "logoFavicon",
                          type: "file",
                          accept: "image/*, icon",
                        },
                        on: { change: _vm.previewLogoFavicon },
                      }),
                      _c(
                        "label",
                        {
                          staticClass:
                            "d-block text-center center-content config-media",
                          attrs: { for: "logoFavicon" },
                        },
                        [
                          !_vm.logoFavicon
                            ? _c("div", [_vm._v("Selecione o favicon")])
                            : _vm._e(),
                          _vm.logoFavicon
                            ? [
                                _c("b-img", {
                                  attrs: {
                                    for: "logoFavicon",
                                    src: _vm.logoFavicon,
                                    fluid: "",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c("b-card", { staticClass: "mb-1" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Logo Desktop "),
                      ]),
                      _c("input", {
                        staticClass: "form-control-file hidden",
                        attrs: {
                          id: "logoDesktop",
                          type: "file",
                          accept: "image/*",
                        },
                        on: { change: _vm.previewLogoDesktop },
                      }),
                      _c(
                        "label",
                        {
                          staticClass:
                            "d-block text-center center-content config-media",
                          attrs: { for: "logoDesktop" },
                        },
                        [
                          !_vm.logoDesktop
                            ? _c("span", [
                                _c("small", [_vm._v("Selecione a logo")]),
                              ])
                            : _vm._e(),
                          _vm.logoDesktop
                            ? [
                                _c("b-img", {
                                  attrs: {
                                    for: "logoDesktop",
                                    src: _vm.logoDesktop,
                                    fluid: "",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c("b-card", { staticClass: "mb-1" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Logo Mobile "),
                      ]),
                      _c("input", {
                        staticClass: "form-control-file hidden",
                        attrs: {
                          id: "logoMobile",
                          type: "file",
                          accept: "image/*",
                        },
                        on: { change: _vm.previewLogoMobile },
                      }),
                      _c(
                        "label",
                        {
                          staticClass:
                            "d-block text-center center-content config-media",
                          attrs: { for: "logoMobile" },
                        },
                        [
                          !_vm.logoMobile
                            ? _c("span", {}, [
                                _c("small", [_vm._v("Selecione a logo")]),
                              ])
                            : _vm._e(),
                          _vm.logoMobile
                            ? [
                                _c("b-img", {
                                  attrs: {
                                    for: "logoMobile",
                                    src: _vm.logoMobile,
                                    fluid: "",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c("b-card", { staticClass: "mb-1" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Logo E-mail "),
                      ]),
                      _c("input", {
                        staticClass: "form-control-file hidden",
                        attrs: {
                          id: "logoEmail",
                          type: "file",
                          accept: "image/*",
                        },
                        on: { change: _vm.previewLogoEmail },
                      }),
                      _c(
                        "label",
                        {
                          staticClass:
                            "d-block text-center center-content config-media",
                          attrs: { for: "logoEmail" },
                        },
                        [
                          !_vm.logoEmail
                            ? _c("span", {}, [
                                _c("small", [_vm._v("Selecione a logo")]),
                              ])
                            : _vm._e(),
                          _vm.logoEmail
                            ? [
                                _c("b-img", {
                                  attrs: {
                                    for: "logoEmail",
                                    src: _vm.logoEmail,
                                    fluid: "",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c("b-card", { staticClass: "mb-1" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Logo Sala virtual "),
                      ]),
                      _c("input", {
                        staticClass: "form-control-file hidden",
                        attrs: {
                          id: "logoSalaVirtual",
                          type: "file",
                          accept: "image/*",
                        },
                        on: { change: _vm.previewLogoSalaVirtual },
                      }),
                      _c(
                        "label",
                        {
                          staticClass:
                            "d-block text-center center-content config-media",
                          attrs: { for: "logoSalaVirtual" },
                        },
                        [
                          !_vm.logoSalaVirtual
                            ? _c("span", {}, [
                                _c("small", [_vm._v("Selecione a logo")]),
                              ])
                            : _vm._e(),
                          _vm.logoSalaVirtual
                            ? [
                                _c("b-img", {
                                  attrs: {
                                    for: "logoSalaVirtual",
                                    src: _vm.logoSalaVirtual,
                                    fluid: "",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("SEO"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-card",
                [
                  _c("b-card-text", [
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c("input", {
                            staticClass: "form-control-file hidden",
                            attrs: {
                              id: "seoImage",
                              type: "file",
                              accept: "image/*",
                            },
                            on: { change: _vm.previewSeoImage },
                          }),
                          _c("b-form-group", [
                            _c(
                              "label",
                              {
                                staticClass: "d-block text-center",
                                staticStyle: {
                                  border: "1px solid rgb(222 222 222)",
                                  "border-radius": "4px",
                                  "border-style": "dashed",
                                  cursor: "pointer",
                                  "font-size": "1rem",
                                  "margin-bottom": "0px",
                                  height: "160px",
                                },
                                attrs: { for: "seoImage" },
                              },
                              [
                                !_vm.seoImage
                                  ? _c("div", [
                                      _c(
                                        "small",
                                        {
                                          staticStyle: {
                                            padding: "50px 10px",
                                            display: "block",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Selecione a imagem de destaque que aparecerá ao compartilhar o domínio da empresa "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.seoImage
                                  ? [
                                      _c("b-img", {
                                        staticStyle: { height: "100%" },
                                        attrs: {
                                          for: "image",
                                          src: _vm.seoImage,
                                          fluid: "",
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-md-8" }, [
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Título "),
                            ]),
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.infos.seo_title,
                                    expression: "infos.seo_title",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.$v.infos.seo_title.$error,
                                },
                                attrs: { type: "text" },
                                domProps: { value: _vm.infos.seo_title },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.infos,
                                      "seo_title",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Descrição "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.v-dark",
                                    modifiers: { hover: true, "v-dark": true },
                                  },
                                ],
                                staticClass: "bi bi-question-circle text-1rem",
                                attrs: {
                                  title:
                                    "Recomendável uma descrição com 50 à 300 caracteres.",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.infos.seo_description,
                                    expression: "infos.seo_description",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.$v.infos.seo_description.$error,
                                },
                                attrs: { type: "text" },
                                domProps: { value: _vm.infos.seo_description },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.infos,
                                      "seo_description",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Contatos"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-card",
                [
                  _c("b-card-text", [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" E-mail - Formulário de contato "),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.contacts.contact_email,
                              expression: "contacts.contact_email",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.$v.contacts.contact_email.$error,
                          },
                          attrs: { type: "email" },
                          domProps: { value: _vm.contacts.contact_email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.contacts,
                                "contact_email",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-card",
                [
                  _c("b-card-text", [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" Telefone Presencial "),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["(##) ####-####"],
                                expression: "['(##) ####-####']",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.contacts.phone_1,
                                expression: "contacts.phone_1",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.$v.contacts.phone_1.$error,
                            },
                            attrs: {
                              type: "tel",
                              placeholder: "(00) 0000-0000",
                            },
                            domProps: { value: _vm.contacts.phone_1 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.contacts,
                                  "phone_1",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" E-mail Presencial "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("b-form-input", {
                              model: {
                                value: _vm.contacts.email_1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.contacts, "email_1", $$v)
                                },
                                expression: "contacts.email_1",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Horário de atendimento "),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.contacts.attendance_1,
                                expression: "contacts.attendance_1",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.$v.contacts.attendance_1.$error,
                            },
                            attrs: {
                              type: "text",
                              placeholder: "2ª a 6ª, das 08 às 20:30 horas",
                            },
                            domProps: { value: _vm.contacts.attendance_1 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.contacts,
                                  "attendance_1",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-row mt-1" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Telefone Online" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["(##) ####-####"],
                                    expression: "['(##) ####-####']",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.contacts.phone_2,
                                    expression: "contacts.phone_2",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "tel",
                                  placeholder: "(00) 0000-0000",
                                },
                                domProps: { value: _vm.contacts.phone_2 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.contacts,
                                      "phone_2",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "E-mail Online" } },
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.contacts.email_2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contacts, "email_2", $$v)
                                  },
                                  expression: "contacts.email_2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Horário de atendimento" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.contacts.attendance_2,
                                    expression: "contacts.attendance_2",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.contacts.attendance_2 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.contacts,
                                      "attendance_2",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-row mt-1" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Telefone Apostilas" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["(##) ####-####"],
                                    expression: "['(##) ####-####']",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.contacts.phone_3,
                                    expression: "contacts.phone_3",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "tel",
                                  placeholder: "(00) 0000-0000",
                                },
                                domProps: { value: _vm.contacts.phone_3 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.contacts,
                                      "phone_3",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "E-mail Apostilas" } },
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.contacts.email_3,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contacts, "email_3", $$v)
                                  },
                                  expression: "contacts.email_3",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Horário de atendimento" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.contacts.attendance_3,
                                    expression: "contacts.attendance_3",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.contacts.attendance_3 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.contacts,
                                      "attendance_3",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-row mt-1" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Telefone Assessoria de Imprensa",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["(##) ####-####"],
                                    expression: "['(##) ####-####']",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.contacts.phone_4,
                                    expression: "contacts.phone_4",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "tel",
                                  placeholder: "(00) 0000-0000",
                                },
                                domProps: { value: _vm.contacts.phone_4 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.contacts,
                                      "phone_4",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { label: "E-mail Assessoria de Imprensa" },
                            },
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.contacts.email_4,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contacts, "email_4", $$v)
                                  },
                                  expression: "contacts.email_4",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Horário de atendimento" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.contacts.attendance_4,
                                    expression: "contacts.attendance_4",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.contacts.attendance_4 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.contacts,
                                      "attendance_4",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Página de Obrigado"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "form-row mb-1" }, [
                _c("div", { staticClass: "col-md-10" }, [
                  _c("h4", [
                    _vm._v("Configure a página de obrigado do seu jeito."),
                  ]),
                  _c("p", { staticClass: "mb-0" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://" + _vm.infos.domain + "/obrigado",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Clique aqui")]
                    ),
                    _vm._v(" para visualizar a página de obrigado. "),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-2 text-right" },
                  [
                    _c("v-select", {
                      attrs: {
                        label: "title",
                        "item-text": "title",
                        "item-value": "id",
                        options: [
                          { code: 1, title: "Ativo" },
                          { code: 0, title: "Inativo" },
                        ],
                        required: "",
                      },
                      model: {
                        value: _vm.thankyoupage.active,
                        callback: function ($$v) {
                          _vm.$set(_vm.thankyoupage, "active", $$v)
                        },
                        expression: "thankyoupage.active",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "b-card",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-card-text",
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Título "),
                      ]),
                      _c("editor", {
                        staticClass: "about-editor-1",
                        attrs: {
                          "api-key":
                            "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                          init: this.optionEditor({
                            height: 250,
                            selector: "about-editor-1",
                          }),
                        },
                        model: {
                          value: _vm.thankyoupage.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.thankyoupage, "title", $$v)
                          },
                          expression: "thankyoupage.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-card-text",
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Descrição "),
                      ]),
                      _c("editor", {
                        staticClass: "about-editor-2",
                        attrs: {
                          "api-key":
                            "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                          init: this.optionEditor({
                            height: 250,
                            selector: "about-editor-2",
                          }),
                        },
                        model: {
                          value: _vm.thankyoupage.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.thankyoupage, "description", $$v)
                          },
                          expression: "thankyoupage.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                [
                  _c("b-card-text", [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Vídeo "),
                      _c("small", [_vm._v("Código do YouTube")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "b-input-group",
                          {
                            staticClass: "input-group-merge",
                            attrs: {
                              prepend: "https://www.youtube.com/watch?v=",
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "text-primary pl-1",
                              class: {
                                "is-invalid":
                                  _vm.$v.thankyoupage.video_code.$error,
                              },
                              model: {
                                value: _vm.thankyoupage.video_code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.thankyoupage, "video_code", $$v)
                                },
                                expression: "thankyoupage.video_code",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Redes Sociais"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-card",
                [
                  _c("b-card-text", [
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      _vm._l(_vm.sociais, function (rede, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "col-md-4" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "text-capitalize",
                                attrs: { for: "" },
                              },
                              [_vm._v(" " + _vm._s(index) + " ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "b-input-group",
                                  {
                                    staticClass: "input-group-merge",
                                    attrs: { prepend: "@" },
                                  },
                                  [
                                    _c("b-form-input", {
                                      staticClass: "text-primary pl-1",
                                      model: {
                                        value: _vm.sociais[index],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.sociais, index, $$v)
                                        },
                                        expression: "sociais[index]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Notificações"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "p-1" }, [
                _c("p", [
                  _vm._v(
                    " Antes de ativar qualquer notificação, tenha certeza de que os templates e demais informações foram cadastrados corretamente. "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-card",
                      { attrs: { header: "Aluno" } },
                      [
                        _c("b-card-text", [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row d-flex justify-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "col-md-9" }, [
                                        _c("span", [_vm._v("Cadastro")]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3 text-right" },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "new_customer",
                                              name: "check-button",
                                              switch: "",
                                              inline: "",
                                            },
                                            model: {
                                              value:
                                                _vm.notifications.customer_new,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.notifications,
                                                  "customer_new",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "notifications.customer_new",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row d-flex justify-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "col-md-9" }, [
                                        _c("span", [
                                          _vm._v("Recuperação de senha"),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3 text-right" },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "customer_forget_password",
                                              name: "check-button",
                                              switch: "",
                                              inline: "",
                                            },
                                            model: {
                                              value:
                                                _vm.notifications
                                                  .customer_forget_password,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.notifications,
                                                  "customer_forget_password",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "notifications.customer_forget_password",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row d-flex justify-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "col-md-9" }, [
                                        _c("span", [
                                          _vm._v("Senha atualizada"),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3 text-right" },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "customer_update_password",
                                              name: "check-button",
                                              switch: "",
                                              inline: "",
                                            },
                                            model: {
                                              value:
                                                _vm.notifications
                                                  .customer_update_password,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.notifications,
                                                  "customer_update_password",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "notifications.customer_update_password",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row d-flex justify-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "col-md-9" }, [
                                        _c("span", [
                                          _vm._v("Pedido realizado"),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3 text-right" },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "customer_payment_online_new",
                                              name: "check-button",
                                              switch: "",
                                              inline: "",
                                            },
                                            model: {
                                              value:
                                                _vm.notifications
                                                  .customer_payment_online_new,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.notifications,
                                                  "customer_payment_online_new",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "notifications.customer_payment_online_new",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row d-flex justify-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "col-md-9" }, [
                                        _c("span", [
                                          _vm._v("Pagamento aprovado"),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3 text-right" },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "customer_payment_online_paid",
                                              name: "check-button",
                                              switch: "",
                                              inline: "",
                                            },
                                            model: {
                                              value:
                                                _vm.notifications
                                                  .customer_payment_online_paid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.notifications,
                                                  "customer_payment_online_paid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "notifications.customer_payment_online_paid",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row d-flex justify-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "col-md-9" }, [
                                        _c("span", [
                                          _vm._v("Pagamento rejeitado"),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3 text-right" },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "customer_payment_online_rejected",
                                              name: "check-button",
                                              switch: "",
                                              inline: "",
                                            },
                                            model: {
                                              value:
                                                _vm.notifications
                                                  .customer_payment_online_rejected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.notifications,
                                                  "customer_payment_online_rejected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                          notifications.customer_payment_online_rejected\n                        ",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-1", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row d-flex justify-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "col-md-9" }, [
                                        _c("span", [
                                          _vm._v("Desconto de aniversário"),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3 text-right" },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "customer_discount_birthday",
                                              name: "check-button",
                                              switch: "",
                                              inline: "",
                                            },
                                            model: {
                                              value:
                                                _vm.notifications
                                                  .customer_discount_birthday,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.notifications,
                                                  "customer_discount_birthday",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "notifications.customer_discount_birthday",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.notifications.customer_discount_birthday
                            ? _c(
                                "div",
                                {
                                  staticClass: "row py-1",
                                  staticStyle: {
                                    background: "#f8f8f8",
                                    "border-radius": "3px",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row d-flex justify-content-between",
                                      },
                                      [
                                        _c("div", { staticClass: "col-md-9" }, [
                                          _vm._v(" Cadastre o "),
                                          _c("strong", [_vm._v("percentual")]),
                                          _vm._v(
                                            " de desconto que você concederá para o cliente. "
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                size: "sm",
                                                placeholder: "Desconto %",
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.infos.discount_birthday,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.infos,
                                                    "discount_birthday",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "infos.discount_birthday",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-card",
                      { attrs: { header: "Usuário" } },
                      [
                        _c("b-card-text", [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row d-flex justify-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "col-md-9" }, [
                                        _c("span", [_vm._v("Cadastro")]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3 text-right" },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "new_user",
                                              name: "check-button",
                                              switch: "",
                                              inline: "",
                                            },
                                            model: {
                                              value: _vm.contacts.new_user,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contacts,
                                                  "new_user",
                                                  $$v
                                                )
                                              },
                                              expression: "contacts.new_user",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Metas e Scripts"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-tabs",
                {
                  staticClass: "tabs-scripts",
                  attrs: { pills: "", horizontal: "" },
                },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Metas", active: "" },
                      on: {
                        click: function ($event) {
                          _vm.typeMetaScript = "metas"
                        },
                      },
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-card",
                            [
                              _c("p", [
                                _vm._v(" Exemplo: "),
                                _c(
                                  "code",
                                  { staticClass: "highlighter-rouge" },
                                  [
                                    _vm._v(
                                      '<meta name="adopt-website-id" content="e3ebcb22-f7c0-44e2-b506-52985389608e" >'
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "d-block mt-2" },
                                [
                                  _c(
                                    "b-form",
                                    {
                                      ref: "formMetas",
                                      staticClass: "repeater-form",
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.repeateAgain.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(_vm.metas, function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          ref: "rowMetas",
                                          refInFor: true,
                                          staticClass: "form-row",
                                          attrs: { id: item.id },
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "4", lg: "4" } },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: "" } },
                                                [_vm._v("Name")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: item.name,
                                                        expression: "item.name",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "item-name",
                                                      type: "text",
                                                    },
                                                    domProps: {
                                                      value: item.name,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          item,
                                                          "name",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "7", lg: "7" } },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: "" } },
                                                [_vm._v("Content")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: item.content,
                                                        expression:
                                                          "item.content",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "item-content",
                                                      type: "text",
                                                    },
                                                    domProps: {
                                                      value: item.content,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          item,
                                                          "content",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "mb-50",
                                              attrs: { lg: "1", md: "1" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "invisible",
                                                      attrs: { for: "" },
                                                    },
                                                    [_vm._v("Ação")]
                                                  ),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "ripple",
                                                          rawName:
                                                            "v-ripple.400",
                                                          value:
                                                            "rgba(234, 84, 85, 0.15)",
                                                          expression:
                                                            "'rgba(234, 84, 85, 0.15)'",
                                                          modifiers: {
                                                            400: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass: "mt-0",
                                                      attrs: {
                                                        variant:
                                                          "outline-danger",
                                                        block: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeItem(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-x-square",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { 400: true },
                                    },
                                  ],
                                  attrs: { variant: "primary" },
                                  on: { click: _vm.repeateAgain },
                                },
                                [
                                  _c("i", { staticClass: "bi bi-plus mr-25" }),
                                  _c("span", [_vm._v("Novo")]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Scripts" },
                      on: {
                        click: function ($event) {
                          _vm.typeMetaScript = "scripts"
                        },
                      },
                    },
                    [
                      _c(
                        "b-card",
                        [
                          _c(
                            "b-card-text",
                            [
                              _c(
                                "div",
                                { staticClass: "d-block" },
                                [
                                  _c(
                                    "b-form",
                                    {
                                      ref: "formScripts",
                                      staticClass: "repeater-form",
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.repeateAgain.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(_vm.scripts, function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          ref: "rowScripts",
                                          refInFor: true,
                                          staticClass: "form-row",
                                          attrs: { id: item.id },
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3", lg: "3" } },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: "" } },
                                                [_vm._v("Tipo")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      label: "title",
                                                      "item-text": "title",
                                                      "item-value": "id",
                                                      options:
                                                        _vm.optionsScriptTypes,
                                                      required: "",
                                                    },
                                                    model: {
                                                      value: item.type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.type",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          item.type &&
                                          item.type.id === "internal"
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "8", lg: "8" } },
                                                [
                                                  _c(
                                                    "label",
                                                    { attrs: { for: "" } },
                                                    [_vm._v("Content")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: item.content,
                                                            expression:
                                                              "item.content",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "item-content",
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value: item.content,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              item,
                                                              "content",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          item.type &&
                                          item.type.id === "external"
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "3", lg: "3" } },
                                                [
                                                  _c(
                                                    "label",
                                                    { attrs: { for: "" } },
                                                    [_vm._v("Class(seletor)")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: item.class,
                                                            expression:
                                                              "item.class",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "item-content",
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value: item.class,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              item,
                                                              "class",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          item.type &&
                                          item.type.id === "external"
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "5", lg: "5" } },
                                                [
                                                  _c(
                                                    "label",
                                                    { attrs: { for: "" } },
                                                    [_vm._v("Link(src)")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: item.link,
                                                            expression:
                                                              "item.link",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "item-content",
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value: item.link,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              item,
                                                              "link",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "mb-50",
                                              attrs: { lg: "1", md: "1" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "invisible",
                                                      attrs: { for: "" },
                                                    },
                                                    [_vm._v("Ação")]
                                                  ),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "ripple",
                                                          rawName:
                                                            "v-ripple.400",
                                                          value:
                                                            "rgba(234, 84, 85, 0.15)",
                                                          expression:
                                                            "'rgba(234, 84, 85, 0.15)'",
                                                          modifiers: {
                                                            400: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass: "mt-0",
                                                      attrs: {
                                                        variant:
                                                          "outline-danger",
                                                        block: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeItem(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-x-square",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { 400: true },
                                    },
                                  ],
                                  attrs: { variant: "primary" },
                                  on: { click: _vm.repeateAgain },
                                },
                                [
                                  _c("i", { staticClass: "bi bi-plus mr-25" }),
                                  _c("span", [_vm._v("Novo")]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Termos e etc."),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-tabs",
                { attrs: { pills: "", horizontal: "" } },
                [_c("b-card", [_c("b-card-text")], 1)],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Frete"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-tabs",
                { attrs: { pills: "", horizontal: "" } },
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("p"),
                          _c(
                            "b-alert",
                            {
                              staticClass: "mb-1",
                              attrs: {
                                variant: "dark",
                                show: "",
                                "d-block": "",
                              },
                            },
                            [
                              _c("h4", { staticClass: "alert-heading" }, [
                                _vm._v("Website dos Correios"),
                              ]),
                              _c("div", { staticClass: "alert-body" }, [
                                _vm._v(
                                  " Caso o webservice dos Correios esteja indisponível no momento do calculo do frete e prazo, é preciso cadastrar o valor a ser cobrado por apostila e o prazo de entrega. "
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "form-row d-flex mt-2" }, [
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Prazo entrega"),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.shipping.days,
                                      expression: "shipping.days",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid": _vm.$v.shipping.days.$error,
                                  },
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.shipping.days },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shipping,
                                        "days",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Valor"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "money",
                                    _vm._b(
                                      {
                                        staticClass: "form-control",
                                        class: {
                                          "is-invalid":
                                            _vm.$v.shipping.price.$error,
                                        },
                                        attrs: { placeholder: "0,00" },
                                        model: {
                                          value: _vm.shipping.price,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.shipping, "price", $$v)
                                          },
                                          expression: "shipping.price",
                                        },
                                      },
                                      "money",
                                      _vm.money,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("CEP origiem"),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.shipping.zipcode,
                                      expression: "shipping.zipcode",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.shipping.zipcode.$error,
                                  },
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.shipping.zipcode },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shipping,
                                        "zipcode",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Nº contrato"),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.shipping.contract,
                                      expression: "shipping.contract",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.shipping.contract.$error,
                                  },
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.shipping.contract },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shipping,
                                        "contract",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Senha"),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.shipping.password,
                                      expression: "shipping.password",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.shipping.password.$error,
                                  },
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.shipping.password },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shipping,
                                        "password",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Serviços"),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.shipping.services,
                                      expression: "shipping.services",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.shipping.services.$error,
                                  },
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.shipping.services },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shipping,
                                        "services",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row d-flex justify-content-end" }, [
        _c("div", { staticClass: "col-md-3" }, [
          _c("button", { staticClass: "btn btn-block btn-success" }, [
            _vm.submited
              ? _c(
                  "div",
                  [
                    _c("b-spinner", { attrs: { small: "", variant: "light" } }),
                    _vm._v(" Verificando... "),
                  ],
                  1
                )
              : _c("div", [_vm._v("Salvar")]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }