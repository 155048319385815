<template>
  <b-form @submit.prevent="submitUpdate">
    <b-tabs content-class="mt-0" class="mb-0 tabs-school" vertical>
      <b-tab active>
        <template #title>
          <span class="font-weight-bold">Informações</span>
        </template>

        <div class="form-row">
          <div class="col-md-12">
            <b-card class="mb-1">
              <b-card-text>
                <div class="form-row">
                  <div class="col-md-12">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Razão Social
                    </label>
                    <div class="form-group">
                      <b-form-input
                        v-model="infos.trading_name"
                        :class="{
                          'is-invalid': $v.infos.trading_name.$error,
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Nome Fantasia
                    </label>
                    <div class="form-group mb-m-1 mb-0">
                      <b-form-input
                        v-model="infos.company_name"
                        :class="{
                          'is-invalid': $v.infos.company_name.$error,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      CNPJ
                    </label>
                    <div class="form-group mb-m-1 mb-0">
                      <input
                        v-mask="['##.###.###/####-##']"
                        type="tel"
                        v-model="infos.document"
                        :class="{ 'is-invalid': $v.infos.document.$error }"
                        placeholder="000.000.000-00"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-md-2">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Cor principal
                    </label>
                    <input
                      class="form-control"
                      :style="`color: ${infos.background};`"
                      v-model="infos.background"
                      :class="{ 'is-invalid': $v.infos.background.$error }"
                    />
                  </div>
                </div>
              </b-card-text>
            </b-card>

            <div class="form-row">
              <div class="col-md-4">
                <b-card class="mb-1">
                  <b-card-text>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      WhatsApp <i class="bi bi-whatsapp"></i>
                    </label>
                    <input
                      v-mask="['(##) #####-####', '(##) ####-####']"
                      type="tel"
                      v-model="contacts.whatsapp"
                      :class="{ 'is-invalid': $v.contacts.whatsapp.$error }"
                      placeholder="(00) 0000-0000"
                      class="form-control"
                    />
                  </b-card-text>
                </b-card>
              </div>

              <div class="col-md-8">
                <b-card class="mb-1">
                  <b-card-text>
                    <div
                      class="d-flex"
                      style="align-items: center; column-gap: 20px"
                    >
                      <div>
                        <label for="">
                          <i class="text-danger bi bi-record-circle"></i>
                          WhatsApp Botão <i class="bi bi-whatsapp"></i>
                        </label>
                        <input
                          v-mask="['(##) #####-####', '(##) ####-####']"
                          type="tel"
                          v-model="contacts.whatsapp_button"
                          :class="{
                            'is-invalid': $v.contacts.whatsapp_button.$error,
                          }"
                          placeholder="(00) 0000-0000"
                          class="form-control"
                        />
                      </div>

                      <div>
                        <b-form-checkbox
                          class="custom-control-areas custom-control-success float-left"
                          name="check-button"
                          switch
                          inline
                          :checked="infos.display_whatsapp_home"
                          v-model="infos.display_whatsapp_home"
                          :value="1"
                        >
                          <span class="switch-icon-left">
                            <i class="bi bi-check"></i>
                          </span>
                          <span class="switch-icon-right">
                            <i class="bi bi-x"></i>
                          </span>
                        </b-form-checkbox>
                        Exibir na Home
                      </div>
                    </div>
                  </b-card-text>
                </b-card>
              </div>
            </div>

            <b-card class="mb-1">
              <b-card-text>
                <div class="form-row">
                  <div class="col-md-4">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Sales Force - OID
                    </label>
                    <div class="form-group mb-1 mb-md-0">
                      <b-form-input
                        v-model="infos.sales_force_oid"
                        :class="{
                          'is-invalid': $v.infos.sales_force_oid.$error,
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Sales Force - Record Type
                    </label>
                    <div class="form-group mb-1 mb-md-0">
                      <b-form-input
                        v-model="infos.sales_force_record_type"
                        :class="{
                          'is-invalid': $v.infos.sales_force_record_type.$error,
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Código GTM
                    </label>
                    <div class="form-group mb-0 mb-md-0">
                      <b-form-input
                        v-model="infos.gtm"
                        :class="{
                          'is-invalid': $v.infos.gtm.$error,
                        }"
                      />
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-card>

            <b-card class="mb-1">
              <b-card-text>
                <div
                  class="form-row"
                  style="
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                  "
                >
                  <div class="col-md-4">
                    <b-form-checkbox
                      class="custom-control-areas custom-control-success float-left"
                      name="check-button"
                      switch
                      inline
                      :checked="infos.black_friday_active"
                      v-model="infos.black_friday_active"
                      :value="1"
                    >
                      <span class="switch-icon-left">
                        <i class="bi bi-check"></i>
                      </span>
                      <span class="switch-icon-right">
                        <i class="bi bi-x"></i>
                      </span>
                    </b-form-checkbox>
                    Ativar Black Friday
                  </div>

                  <div class="col-md-8">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Consys - Canal do aluno
                    </label>
                    <div class="form-group mb-0 mb-md-0">
                      <b-form-input
                        v-model="infos.channel"
                        :class="{
                          'is-invalid': $v.infos.channel.$error,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    <b-form-checkbox
                      class="custom-control-areas custom-control-success float-left"
                      name="check-button"
                      switch
                      inline
                      :checked="tickets"
                      v-model="tickets"
                      :value="1"
                      :unchecked-value="0"
                    >
                      <span class="switch-icon-left">
                        <i class="bi bi-check"></i>
                      </span>
                      <span class="switch-icon-right">
                        <i class="bi bi-x"></i>
                      </span>
                    </b-form-checkbox>
                    Abertura de Tickets
                  </div>
                </div>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </b-tab>

      <b-tab>
        <template #title>
          <span class="font-weight-bold">Facebook API</span>
        </template>

        <div class="form-row">
          <div class="col-md-12">
            <b-card class="mb-1">
              <b-card-text>
                <div class="form-row">
                  <div class="col-md-4">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Facebook - Pixel ID
                    </label>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': $v.infos.facebook_pixel_id.$error,
                        }"
                        v-model="infos.facebook_pixel_id"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Facebook App - ID
                    </label>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': $v.infos.facebook_app_id.$error,
                        }"
                        v-model="infos.facebook_app_id"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Facebook App - Chave secreta
                    </label>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': $v.infos.facebook_app_secret_key.$error,
                        }"
                        v-model="infos.facebook_app_secret_key"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Token do usuário do sistema
                      <i
                        v-b-tooltip.hover.v-dark
                        class="bi bi-question-circle text-1rem"
                        title="Acesse o Facebook Business > Confogurações > Usuários > Usuários do sistema. "
                      ></i>
                    </label>
                    <div class="form-group">
                      <b-form-input
                        v-model="infos.facebook_user_token"
                        :class="{
                          'is-invalid': $v.infos.facebook_user_token.$error,
                        }"
                      />
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </b-tab>

      <b-tab>
        <template #title>
          <span class="font-weight-bold">Logos</span>
        </template>

        <div class="form-row">
          <div class="col-md-3">
            <b-card class="mb-1">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Favicon
              </label>
              <input
                id="logoFavicon"
                type="file"
                accept="image/*, icon"
                class="form-control-file hidden"
                @change="previewLogoFavicon"
              />
              <label
                for="logoFavicon"
                class="d-block text-center center-content config-media"
              >
                <div v-if="!logoFavicon">Selecione o favicon</div>
                <template v-if="logoFavicon">
                  <b-img for="logoFavicon" :src="logoFavicon" fluid />
                </template>
              </label>
            </b-card>
          </div>

          <div class="col-md-3">
            <b-card class="mb-1">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Logo Desktop
              </label>
              <input
                id="logoDesktop"
                type="file"
                accept="image/*"
                class="form-control-file hidden"
                @change="previewLogoDesktop"
              />
              <label
                for="logoDesktop"
                class="d-block text-center center-content config-media"
              >
                <span v-if="!logoDesktop">
                  <small>Selecione a logo</small>
                </span>
                <template v-if="logoDesktop">
                  <b-img for="logoDesktop" :src="logoDesktop" fluid />
                </template>
              </label>
            </b-card>
          </div>

          <div class="col-md-3">
            <b-card class="mb-1">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Logo Mobile
              </label>

              <input
                id="logoMobile"
                type="file"
                accept="image/*"
                class="form-control-file hidden"
                @change="previewLogoMobile"
              />

              <label
                for="logoMobile"
                class="d-block text-center center-content config-media"
              >
                <span v-if="!logoMobile" style="">
                  <small>Selecione a logo</small>
                </span>
                <template v-if="logoMobile">
                  <b-img for="logoMobile" :src="logoMobile" fluid />
                </template>
              </label>
            </b-card>
          </div>

          <div class="col-md-3">
            <b-card class="mb-1">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Logo E-mail
              </label>

              <input
                id="logoEmail"
                type="file"
                accept="image/*"
                class="form-control-file hidden"
                @change="previewLogoEmail"
              />
              <label
                for="logoEmail"
                class="d-block text-center center-content config-media"
              >
                <span v-if="!logoEmail" style="">
                  <small>Selecione a logo</small>
                </span>
                <template v-if="logoEmail">
                  <b-img for="logoEmail" :src="logoEmail" fluid />
                </template>
              </label>
            </b-card>
          </div>

          <div class="col-md-3">
            <b-card class="mb-1">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Logo Sala virtual
              </label>
              <input
                id="logoSalaVirtual"
                type="file"
                accept="image/*"
                class="form-control-file hidden"
                @change="previewLogoSalaVirtual"
              />
              <label
                for="logoSalaVirtual"
                class="d-block text-center center-content config-media"
              >
                <span v-if="!logoSalaVirtual" style="">
                  <small>Selecione a logo</small>
                </span>
                <template v-if="logoSalaVirtual">
                  <b-img for="logoSalaVirtual" :src="logoSalaVirtual" fluid />
                </template>
              </label>
            </b-card>
          </div>
        </div>
      </b-tab>

      <b-tab>
        <template #title>
          <span class="font-weight-bold">SEO</span>
        </template>

        <b-card>
          <b-card-text>
            <div class="form-row">
              <div class="col-md-4">
                <input
                  id="seoImage"
                  type="file"
                  accept="image/*"
                  class="form-control-file hidden"
                  @change="previewSeoImage"
                />
                <b-form-group>
                  <label
                    for="seoImage"
                    class="d-block text-center"
                    style="
                      border: 1px solid rgb(222 222 222);
                      border-radius: 4px;
                      border-style: dashed;
                      cursor: pointer;
                      font-size: 1rem;
                      margin-bottom: 0px;
                      height: 160px;
                    "
                  >
                    <div v-if="!seoImage">
                      <small style="padding: 50px 10px; display: block">
                        Selecione a imagem de destaque que aparecerá ao
                        compartilhar o domínio da empresa
                      </small>
                    </div>
                    <template v-if="seoImage">
                      <b-img
                        for="image"
                        :src="seoImage"
                        style="height: 100%"
                        fluid
                      />
                    </template>
                  </label>
                </b-form-group>
              </div>
              <div class="col-md-8">
                <div class="form-row">
                  <div class="col-md-12">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Título
                    </label>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': $v.infos.seo_title.$error }"
                        v-model="infos.seo_title"
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Descrição
                      <i
                        v-b-tooltip.hover.v-dark
                        class="bi bi-question-circle text-1rem"
                        title="Recomendável uma descrição com 50 à 300 caracteres."
                      ></i>
                    </label>
                    <div class="form-group">
                      <input
                        type="text"
                        :class="{
                          'is-invalid': $v.infos.seo_description.$error,
                        }"
                        class="form-control"
                        v-model="infos.seo_description"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Contatos</span>
        </template>
        <b-card>
          <b-card-text>
            <div class="form-row">
              <div class="col-md-6">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  E-mail - Formulário de contato
                </label>
                <input
                  type="email"
                  v-model="contacts.contact_email"
                  :class="{ 'is-invalid': $v.contacts.contact_email.$error }"
                  class="form-control"
                />
              </div>
            </div>
          </b-card-text>
        </b-card>

        <b-card>
          <b-card-text>
            <div class="form-row">
              <div class="col-md-4">
                <label for=""> Telefone Presencial </label>
                <div class="form-group">
                  <input
                    v-mask="['(##) ####-####']"
                    type="tel"
                    v-model="contacts.phone_1"
                    :class="{ 'is-invalid': $v.contacts.phone_1.$error }"
                    placeholder="(00) 0000-0000"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  E-mail Presencial
                </label>
                <div class="form-group">
                  <b-form-input v-model="contacts.email_1" />
                </div>
              </div>
              <div class="col-md-4">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Horário de atendimento
                </label>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="contacts.attendance_1"
                    :class="{ 'is-invalid': $v.contacts.attendance_1.$error }"
                    placeholder="2ª a 6ª, das 08 às 20:30 horas"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="form-row mt-1">
              <div class="col-md-4">
                <b-form-group label="Telefone Online">
                  <input
                    v-mask="['(##) ####-####']"
                    type="tel"
                    v-model="contacts.phone_2"
                    placeholder="(00) 0000-0000"
                    class="form-control"
                  />
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="E-mail Online">
                  <b-form-input v-model="contacts.email_2" />
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Horário de atendimento">
                  <input
                    type="text"
                    v-model="contacts.attendance_2"
                    class="form-control"
                  />
                </b-form-group>
              </div>
            </div>

            <div class="form-row mt-1">
              <div class="col-md-4">
                <b-form-group label="Telefone Apostilas">
                  <input
                    v-mask="['(##) ####-####']"
                    type="tel"
                    v-model="contacts.phone_3"
                    placeholder="(00) 0000-0000"
                    class="form-control"
                  />
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="E-mail Apostilas">
                  <b-form-input v-model="contacts.email_3" />
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Horário de atendimento">
                  <input
                    type="text"
                    v-model="contacts.attendance_3"
                    class="form-control"
                  />
                </b-form-group>
              </div>
            </div>

            <div class="form-row mt-1">
              <div class="col-md-4">
                <b-form-group label="Telefone Assessoria de Imprensa">
                  <input
                    v-mask="['(##) ####-####']"
                    type="tel"
                    v-model="contacts.phone_4"
                    placeholder="(00) 0000-0000"
                    class="form-control"
                  />
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="E-mail Assessoria de Imprensa">
                  <b-form-input v-model="contacts.email_4" />
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Horário de atendimento">
                  <input
                    type="text"
                    v-model="contacts.attendance_4"
                    class="form-control"
                  />
                </b-form-group>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Página de Obrigado</span>
        </template>
        <div class="form-row mb-1">
          <div class="col-md-10">
            <h4>Configure a página de obrigado do seu jeito.</h4>
            <p class="mb-0">
              <a :href="`https://${infos.domain}/obrigado`" target="_blank"
                >Clique aqui</a
              >
              para visualizar a página de obrigado.
            </p>
          </div>
          <div class="col-md-2 text-right">
            <v-select
              label="title"
              item-text="title"
              item-value="id"
              v-model="thankyoupage.active"
              :options="[
                { code: 1, title: 'Ativo' },
                { code: 0, title: 'Inativo' },
              ]"
              required
            >
            </v-select>
          </div>
        </div>
        <b-card class="mb-1">
          <b-card-text>
            <label for="">
              <i class="text-danger bi bi-record-circle"></i>
              Título
            </label>
            <editor
              class="about-editor-1"
              api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
              v-model="thankyoupage.title"
              :init="
                this.optionEditor({
                  height: 250,
                  selector: 'about-editor-1',
                })
              "
            />
          </b-card-text>
        </b-card>

        <b-card class="mb-1">
          <b-card-text>
            <label for="">
              <i class="text-danger bi bi-record-circle"></i>
              Descrição
            </label>
            <editor
              class="about-editor-2"
              api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
              v-model="thankyoupage.description"
              :init="
                this.optionEditor({
                  height: 250,
                  selector: 'about-editor-2',
                })
              "
            />
          </b-card-text>
        </b-card>

        <b-card>
          <b-card-text>
            <label for="">
              <i class="text-danger bi bi-record-circle"></i>
              Vídeo <small>Código do YouTube</small>
            </label>
            <div class="form-group">
              <b-input-group
                prepend="https://www.youtube.com/watch?v="
                class="input-group-merge"
              >
                <b-form-input
                  class="text-primary pl-1"
                  v-model="thankyoupage.video_code"
                  :class="{ 'is-invalid': $v.thankyoupage.video_code.$error }"
                />
              </b-input-group>
            </div>
          </b-card-text>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Redes Sociais</span>
        </template>

        <b-card>
          <b-card-text>
            <div class="form-row">
              <div
                class="col-md-4"
                v-for="(rede, index) in sociais"
                :key="index"
              >
                <label for="" class="text-capitalize">
                  {{ index }}
                </label>
                <div class="form-group">
                  <b-input-group prepend="@" class="input-group-merge">
                    <b-form-input
                      class="text-primary pl-1"
                      v-model="sociais[index]"
                    />
                  </b-input-group>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Notificações</span>
        </template>
        <div class="p-1">
          <p>
            Antes de ativar qualquer notificação, tenha certeza de que os
            templates e demais informações foram cadastrados corretamente.
          </p>
        </div>
        <div class="form-row">
          <div class="col-md-6">
            <b-card header="Aluno">
              <b-card-text>
                <div class="row">
                  <b-col cols="12" class="mb-2">
                    <div class="row d-flex justify-content-between">
                      <div class="col-md-9">
                        <span>Cadastro</span>
                      </div>
                      <div class="col-md-3 text-right">
                        <b-form-checkbox
                          v-model="notifications.customer_new"
                          id="new_customer"
                          name="check-button"
                          switch
                          inline
                        />
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <div class="row d-flex justify-content-between">
                      <div class="col-md-9">
                        <span>Recuperação de senha</span>
                      </div>
                      <div class="col-md-3 text-right">
                        <b-form-checkbox
                          v-model="notifications.customer_forget_password"
                          id="customer_forget_password"
                          name="check-button"
                          switch
                          inline
                        />
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <div class="row d-flex justify-content-between">
                      <div class="col-md-9">
                        <span>Senha atualizada</span>
                      </div>
                      <div class="col-md-3 text-right">
                        <b-form-checkbox
                          v-model="notifications.customer_update_password"
                          id="customer_update_password"
                          name="check-button"
                          switch
                          inline
                        />
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <div class="row d-flex justify-content-between">
                      <div class="col-md-9">
                        <span>Pedido realizado</span>
                      </div>
                      <div class="col-md-3 text-right">
                        <b-form-checkbox
                          v-model="notifications.customer_payment_online_new"
                          id="customer_payment_online_new"
                          name="check-button"
                          switch
                          inline
                        />
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <div class="row d-flex justify-content-between">
                      <div class="col-md-9">
                        <span>Pagamento aprovado</span>
                      </div>
                      <div class="col-md-3 text-right">
                        <b-form-checkbox
                          v-model="notifications.customer_payment_online_paid"
                          id="customer_payment_online_paid"
                          name="check-button"
                          switch
                          inline
                        />
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <div class="row d-flex justify-content-between">
                      <div class="col-md-9">
                        <span>Pagamento rejeitado</span>
                      </div>
                      <div class="col-md-3 text-right">
                        <b-form-checkbox
                          v-model="
                            notifications.customer_payment_online_rejected
                          "
                          id="customer_payment_online_rejected"
                          name="check-button"
                          switch
                          inline
                        />
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mb-1">
                    <div class="row d-flex justify-content-between">
                      <div class="col-md-9">
                        <span>Desconto de aniversário</span>
                      </div>
                      <div class="col-md-3 text-right">
                        <b-form-checkbox
                          v-model="notifications.customer_discount_birthday"
                          id="customer_discount_birthday"
                          name="check-button"
                          switch
                          inline
                        />
                      </div>
                    </div>
                  </b-col>
                </div>
                <div
                  v-if="notifications.customer_discount_birthday"
                  class="row py-1"
                  style="background: #f8f8f8; border-radius: 3px"
                >
                  <div class="col-12">
                    <div class="row d-flex justify-content-between">
                      <div class="col-md-9">
                        Cadastre o <strong>percentual</strong> de desconto que
                        você concederá para o cliente.
                      </div>
                      <div class="col-md-3">
                        <b-form-input
                          size="sm"
                          placeholder="Desconto %"
                          type="number"
                          v-model="infos.discount_birthday"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-card>
          </div>
          <div class="col-md-6">
            <b-card header="Usuário">
              <b-card-text>
                <div class="row">
                  <b-col cols="12" class="mb-2">
                    <div class="row d-flex justify-content-between">
                      <div class="col-md-9">
                        <span>Cadastro</span>
                      </div>
                      <div class="col-md-3 text-right">
                        <b-form-checkbox
                          v-model="contacts.new_user"
                          id="new_user"
                          name="check-button"
                          switch
                          inline
                        />
                      </div>
                    </div>
                  </b-col>
                </div>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Metas e Scripts</span>
        </template>
        <b-tabs pills horizontal class="tabs-scripts">
          <b-tab title="Metas" active @click="typeMetaScript = 'metas'">
            <b-card-text>
              <b-card>
                <p>
                  Exemplo:
                  <code class="highlighter-rouge"
                    >&lt;meta name="adopt-website-id"
                    content="e3ebcb22-f7c0-44e2-b506-52985389608e" &gt;</code
                  >
                </p>

                <div class="d-block mt-2">
                  <b-form
                    ref="formMetas"
                    class="repeater-form"
                    @submit.prevent="repeateAgain"
                  >
                    <div
                      class="form-row"
                      v-for="(item, index) in metas"
                      :id="item.id"
                      :key="index"
                      ref="rowMetas"
                    >
                      <b-col md="4" lg="4">
                        <label for="">Name</label>
                        <div class="form-group">
                          <input
                            id="item-name"
                            type="text"
                            class="form-control"
                            v-model="item.name"
                          />
                        </div>
                      </b-col>

                      <b-col md="7" lg="7">
                        <label for="">Content</label>
                        <div class="form-group">
                          <input
                            id="item-content"
                            type="text"
                            class="form-control"
                            v-model="item.content"
                          />
                        </div>
                      </b-col>

                      <b-col lg="1" md="1" class="mb-50">
                        <div class="form-group">
                          <label for="" class="invisible">Ação</label>
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0"
                            block
                            @click="removeItem(index)"
                          >
                            <i class="bi bi-x-square"></i>
                          </b-button>
                        </div>
                      </b-col>
                    </div>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateAgain"
                >
                  <i class="bi bi-plus mr-25"></i>
                  <span>Novo</span>
                </b-button>
              </b-card>
            </b-card-text>
          </b-tab>
          <b-tab title="Scripts" @click="typeMetaScript = 'scripts'">
            <b-card>
              <b-card-text>
                <div class="d-block">
                  <b-form
                    ref="formScripts"
                    class="repeater-form"
                    @submit.prevent="repeateAgain"
                  >
                    <div
                      class="form-row"
                      v-for="(item, index) in scripts"
                      :id="item.id"
                      :key="index"
                      ref="rowScripts"
                    >
                      <b-col md="3" lg="3">
                        <label for="">Tipo</label>
                        <div class="form-group">
                          <v-select
                            label="title"
                            item-text="title"
                            item-value="id"
                            v-model="item.type"
                            :options="optionsScriptTypes"
                            required
                          >
                          </v-select>
                        </div>
                      </b-col>

                      <b-col
                        md="8"
                        lg="8"
                        v-if="item.type && item.type.id === 'internal'"
                      >
                        <label for="">Content</label>
                        <div class="form-group">
                          <input
                            id="item-content"
                            type="text"
                            class="form-control"
                            v-model="item.content"
                          />
                        </div>
                      </b-col>

                      <b-col
                        md="3"
                        lg="3"
                        v-if="item.type && item.type.id === 'external'"
                      >
                        <label for="">Class(seletor)</label>
                        <div class="form-group">
                          <input
                            id="item-content"
                            type="text"
                            class="form-control"
                            v-model="item.class"
                          />
                        </div>
                      </b-col>

                      <b-col
                        md="5"
                        lg="5"
                        v-if="item.type && item.type.id === 'external'"
                      >
                        <label for="">Link(src)</label>
                        <div class="form-group">
                          <input
                            id="item-content"
                            type="text"
                            class="form-control"
                            v-model="item.link"
                          />
                        </div>
                      </b-col>

                      <b-col lg="1" md="1" class="mb-50">
                        <div class="form-group">
                          <label for="" class="invisible">Ação</label>
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0"
                            block
                            @click="removeItem(index)"
                          >
                            <i class="bi bi-x-square"></i>
                          </b-button>
                        </div>
                      </b-col>
                    </div>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateAgain"
                >
                  <i class="bi bi-plus mr-25"></i>
                  <span>Novo</span>
                </b-button>
              </b-card-text>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Termos e etc.</span>
        </template>
        <b-tabs pills horizontal>
          <b-card>
            <b-card-text> </b-card-text>
          </b-card>
        </b-tabs>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Frete</span>
        </template>
        <b-tabs pills horizontal>
          <b-card>
            <b-card-text>
              <p></p>
              <b-alert variant="dark" show d-block class="mb-1">
                <h4 class="alert-heading">Website dos Correios</h4>
                <div class="alert-body">
                  Caso o webservice dos Correios esteja indisponível no momento
                  do calculo do frete e prazo, é preciso cadastrar o valor a ser
                  cobrado por apostila e o prazo de entrega.
                </div>
              </b-alert>
              <div class="form-row d-flex mt-2">
                <div class="col-md-2">
                  <label for="">Prazo entrega</label>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="shipping.days"
                      :class="{ 'is-invalid': $v.shipping.days.$error }"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="">Valor</label>
                  <div class="form-group">
                    <money
                      class="form-control"
                      placeholder="0,00"
                      v-bind="money"
                      v-model="shipping.price"
                      :class="{ 'is-invalid': $v.shipping.price.$error }"
                    ></money>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="">CEP origiem</label>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="shipping.zipcode"
                      :class="{ 'is-invalid': $v.shipping.zipcode.$error }"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="">Nº contrato</label>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="shipping.contract"
                      :class="{ 'is-invalid': $v.shipping.contract.$error }"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="">Senha</label>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="shipping.password"
                      :class="{ 'is-invalid': $v.shipping.password.$error }"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="">Serviços</label>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="shipping.services"
                      :class="{ 'is-invalid': $v.shipping.services.$error }"
                    />
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </b-tabs>
      </b-tab>
    </b-tabs>

    <div class="row d-flex justify-content-end">
      <div class="col-md-3">
        <button class="btn btn-block btn-success">
          <div v-if="submited">
            <b-spinner small variant="light" /> Verificando...
          </div>
          <div v-else>Salvar</div>
        </button>
      </div>
    </div>
  </b-form>
</template>

<style lang="scss">
.tabs-scripts {
  .nav-link {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.tabs-school {
  .nav-item {
    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      padding-left: 0px;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-right: 0px;
    }
  }

  > .col-auto {
    min-width: 160px;
  }

  .card-scripts textarea {
    min-height: 200px;
    resize: vertical;
  }
}
</style>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTabs,
  BImg,
  BAlert,
  BForm,
  BSpinner,
  BFormGroup,
  BTab,
  BFormCheckbox,
  BCardText,
  BInputGroup,
  VBTooltip,
  VBToggle,
} from "bootstrap-vue";
import { required, minLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import vSelect from "vue-select";
import { Money } from "v-money";
import { onlyNumbers } from "@/_helpers/_urlHelper";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    BFormGroup,
    Money,
    vSelect,
    ButtonsActionsFooter,
    BImg,
    BCardText,
    BInputGroup,
    BTabs,
    editor: Editor,
    BFormCheckbox,
    BFormInput,
    BSpinner,
    BCard,
    BRow,
    BForm,
    BCol,
    BButton,
    BTab,
    BAlert,
    VBTooltip,
    VBToggle,
  },
  watch: {
    "shipping.zipcode": {
      handler() {
        this.shipping.zipcode = onlyNumbers(this.shipping.zipcode);
      },
    },
    "shipping.contract": {
      handler() {
        this.shipping.contract = onlyNumbers(this.shipping.contract);
      },
    },
  },
  validations: {
    infos: {
      company_name: {
        required,
        minLength: minLength(5),
      },
      trading_name: {
        required,
        minLength: minLength(5),
      },
      document: {
        required,
        minLength: minLength(11),
      },
      background: {
        required,
      },
      sales_force_record_type: {
        required,
      },
      sales_force_oid: {
        required,
      },
      channel: {
        required,
      },
      seo_title: {
        required,
      },
      seo_description: {
        required,
      },
      gtm: {
        required,
      },
      facebook_app_secret_key: {
        required,
      },
      facebook_pixel_id: {
        required,
      },
      facebook_user_token: {
        required,
      },
      facebook_app_id: {
        required,
      },
    },
    contacts: {
      phone_1: {
        required,
      },
      // email_1: {
      //   required,
      //   email,
      // },
      attendance_1: {
        required,
      },
      whatsapp: {
        required,
      },
      whatsapp_button: {
        required,
      },
      contact_email: {
        required,
      },
    },
    shipping: {
      price: {
        required,
      },
      days: {
        required,
      },
      zipcode: {
        required,
      },
      services: {
        required,
      },
      contract: {
        required,
      },
      password: {
        required,
      },
    },
    thankyoupage: {
      title: {
        required,
      },
      description: {
        required,
      },
      video_code: {
        required,
      },
      active: {
        required,
      },
    },
  },
  data() {
    return {
      typeMetaScript: "metas",
      nextMetaId: 2,
      nextScriptId: 2,
      logoDesktop: "",
      logoMobile: "",
      logoEmail: "",
      logoSalaVirtual: "",
      logoFavicon: "",
      seoImage: "",
      submited: false,
      tickets: 0,
      infos: {
        logoDesktop: "",
        gtm: "",
        logoMobile: "",
        logoEmail: "",
        logoSalaVirtual: "",
        channel: "",
        logoFavicon: "",
        seoImage: "",
        company_name: "",
        trading_name: "",
        document: "",
        background: "",
        sales_force_oid: "",
        sales_force_record_type: "",
        url_blog: "",
        seo_title: "",
        seo_description: "",
        black_friday_active: 0,
        display_whatsapp_home: 0,
        facebook_app_secret_key: "",
        facebook_pixel_id: "",
        facebook_user_token: "",
        facebook_app_id: "",
      },
      thankyoupage: {
        title: "",
        description: "",
        video_code: "",
        active: {
          title: "Inativo",
          code: 0,
        },
      },
      contacts: {
        phone_1: "",
        phone_2: "",
        phone_3: "",
        phone_4: "",
        email_1: "",
        email_2: "",
        email_3: "",
        email_4: "",
        attendance_1: "",
        attendance_2: "",
        attendance_3: "",
        attendance_4: "",
        contact_email: "",
        whatsapp: "",
        whatsapp_button: "",
      },
      address: {
        address: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        uf: "",
      },
      notifications: {
        user_new: "",
        customer_new: "",
        customer_payment_online_rejected: "",
        customer_payment_online_paid: "",
        customer_payment_online_new: "",
        customer_discount_birthday: "",
        customer_update_password: "",
        customer_forget_password: "",
      },
      sociais: {
        facebook: "",
        instagram: "",
        youtube: "",
        twitter: "",
        linkedin: "",
        tiktok: "",
        telegram: "",
        threads: "",
      },
      shipping: {
        price: "",
        zipcode: "",
        services: "",
        contract: "",
        password: "",
        days: "",
      },
      metas: [
        {
          id: 1,
          name: "",
          content: "",
        },
      ],
      scripts: [
        {
          id: 1,
          type: {
            id: "external",
            title: "Externo - URL",
          },
          content: "",
          link: "",
          class: "",
        },
      ],
      optionsScriptTypes: [
        {
          id: "external",
          title: "Externo - URL",
        },
        {
          id: "internal",
          title: "Interno - Script",
        },
        {
          id: "noscript",
          title: "Noscript",
        },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.$store.dispatch("Config/show").then((res) => {
        if (res) {
          this.infos = res.infos;
          this.tickets = res.tickets;
          this.logoDesktop = this.infos.logo_desktop;
          this.logoEmail = this.infos.logo_email;
          this.logoSalaVirtual = this.infos.logo_sala_virtual;
          this.logoMobile = this.infos.logo_mobile;
          this.logoFavicon = this.infos.logo_favicon;
          this.seoImage = this.infos.seo_image;
          this.thankyoupage = res.thankyoupage;
          this.thankyoupage.active = {
            title: this.thankyoupage.active ? "Ativo" : "Inativo",
            coce: this.thankyoupage.active,
          };
          this.notifications.user_new = res.notifications.user_new
            ? true
            : false;
          this.notifications.customer_new = res.notifications.customer_new
            ? true
            : false;
          this.notifications.customer_payment_online_rejected = res
            .notifications.customer_payment_online_rejected
            ? true
            : false;
          this.notifications.customer_payment_online_paid = res.notifications
            .customer_payment_online_paid
            ? true
            : false;
          this.notifications.customer_payment_online_new = res.notifications
            .customer_payment_online_new
            ? true
            : false;
          this.notifications.customer_discount_birthday = res.notifications
            .customer_discount_birthday
            ? true
            : false;
          this.notifications.customer_update_password = res.notifications
            .customer_update_password
            ? true
            : false;
          this.notifications.customer_forget_password = res.notifications
            .customer_forget_password
            ? true
            : false;

          this.contacts = res.contacts;

          const scripts = res.scripts;

          if (scripts.scripts) {
            this.scripts = JSON.parse(scripts.scripts);
          }

          if (scripts.metas) {
            this.metas = JSON.parse(scripts.metas);
          }

          delete res.shipping.id;
          delete res.shipping.uuid;
          delete res.social.id;
          delete res.social.uuid;

          this.shipping = res.shipping;
          this.sociais = res.social;
        }
      });
    },
    previewLogoEmail(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logoEmail = e.target.result;
        };
        const file = input.files[0];
        this.infos.logoEmail = file;
        reader.readAsDataURL(file);
      }
    },
    previewLogoSalaVirtual(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logoSalaVirtual = e.target.result;
        };
        const file = input.files[0];
        this.infos.logoSalaVirtual = file;
        reader.readAsDataURL(file);
      }
    },
    previewLogoDesktop(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logoDesktop = e.target.result;
        };
        const file = input.files[0];
        this.infos.logoDesktop = file;
        reader.readAsDataURL(file);
      }
    },
    previewLogoFavicon(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logoFavicon = e.target.result;
        };
        const file = input.files[0];
        this.infos.logoFavicon = file;
        reader.readAsDataURL(file);
      }
    },
    previewSeoImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.seoImage = e.target.result;
        };
        const file = input.files[0];
        this.infos.seoImage = file;
        reader.readAsDataURL(file);
      }
    },
    previewLogoMobile(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logoMobile = e.target.result;
        };
        const file = input.files[0];
        this.infos.logoMobile = file;
        reader.readAsDataURL(file);
      }
    },
    async submitUpdate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        const formData = new FormData();
        formData.append("logoFavicon", this.infos.logoFavicon);
        formData.append("logoDesktop", this.infos.logoDesktop);
        formData.append("logoMobile", this.infos.logoMobile);
        formData.append("logoEmail", this.infos.logoEmail);
        formData.append("logoSalaVirtual", this.infos.logoSalaVirtual);
        formData.append("seoImage", this.infos.seoImage);
        formData.append("infos", JSON.stringify(this.infos));
        formData.append("salesforce", JSON.stringify(this.salesforce));
        formData.append("scripts", JSON.stringify(this.scripts));
        formData.append("contacts", JSON.stringify(this.contacts));
        formData.append("notifications", JSON.stringify(this.notifications));
        formData.append("sociais", JSON.stringify(this.sociais));
        formData.append("metas", JSON.stringify(this.metas));
        formData.append("shipping", JSON.stringify(this.shipping));
        formData.append("thankyoupage", JSON.stringify(this.thankyoupage));
        formData.append("tickets", JSON.stringify(this.tickets));

        this.$store
          .dispatch("Config/update", formData)
          .then(() => {
            this.notifyDefault("success");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    repeateAgain() {
      if (this.typeMetaScript === "metas") {
        this.metas.push({
          id: (this.nextMetaId += this.nextMetaId),
        });
      } else {
        this.scripts.push({
          id: (this.nextScriptId += this.nextScriptId),
        });
      }
    },
    removeItem(index) {
      if (this.typeMetaScript === "metas") {
        this.metas.splice(index, 1);
      } else {
        this.scripts.splice(index, 1);
      }
    },
  },
  computed: {
    ...mapState("Config", ["config"]),
  },
};
</script>

<style scoped>
.custom-control-areas .switch-icon-right,
.custom-control-areas .switch-icon-left {
  line-height: 1.8rem;
}
</style>
